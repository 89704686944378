import { Button } from '../../components/ui/button'
import { Input } from '../../components/ui/input'
import { Card } from '../../components/ui/card'
import { Brain, Code, Smartphone, ArrowRight, Mail, Phone, Linkedin } from 'lucide-react'
import { NetworkVisualization } from '../../components/NetworkVisualization'
import { Textarea } from '../../components/ui/textarea'
import { useState,  } from 'react'
import { FaBars, FaInstagram, FaWhatsapp } from 'react-icons/fa';
import logo from '../../assets/images/Logo.png'
import GuilhermeImage from '../../assets/images/Guilherme.png'
import DaviImage from '../../assets/images/Davi.png'
import FelipeImage from '../../assets/images/Felipe.png'
import TobiasImage from '../../assets/images/Tobias.png'
import PedroImage from '../../assets/images/Pedro.png'
import { GoogleAnalytics } from '../../components/GoogleAnalytics'

export default function App() {
  const [modalContent, setModalContent] = useState<'terms' | 'privacy' | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [nomeCompleto, setNomeCompleto] = useState('');
  const [telefone, setTelefone] = useState('');
  const [email, setEmail] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [mensagem, setMensagem] = useState('');

  const scrollToSection = (sectionId: string) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleSubmit = (e: React.MouseEvent) => {
    e.preventDefault();
    window.open(`mailto:contato@flynx.com.br?subject=Contato via site&body=Nome: ${nomeCompleto}%0ATelefone: ${telefone}%0AE-mail: ${email}%0AEmpresa: ${empresa}%0AMensagem: ${mensagem}`);
  };

  return (
    <div className="min-h-screen bg-[#0D0F1B] text-white">
      <header className="fixed top-0 w-full z-50 bg-[#0D0F1B]/80 backdrop-blur-sm border-b border-white/5">
        <div className="container flex h-16 items-center justify-between">
          <div className="flex items-center space-x-2" onClick={() => scrollToSection('#inicio')}>
            <img src={logo} alt="Flynx" className="h-10" />
          </div>
          
          {/* Desktop Navigation */}
          <nav className="hidden md:flex gap-8 items-center">
            <div className="cursor-pointer text-sm text-gray-400 hover:text-white transition-colors" onClick={() => scrollToSection('inicio')}>
              Início
            </div>
            <div className="cursor-pointer text-sm text-gray-400 hover:text-white transition-colors" onClick={() => scrollToSection('solucoes')}>
              Soluções
            </div>
            <div className="cursor-pointer text-sm text-gray-400 hover:text-white transition-colors" onClick={() => scrollToSection('lideres')}>
              Equipe
            </div>
            <div className="cursor-pointer text-sm text-gray-400 hover:text-white transition-colors" onClick={() => scrollToSection('contato')}>
              Contato
            </div>
            <Button className="bg-[#6C47FF] hover:bg-[#7C5AFF]" onClick={() => scrollToSection('contato')}>
              Entre em contato
            </Button>
          </nav>

          {/* Mobile Navigation */}
          <div className="md:hidden">
            <Button variant="ghost" className="p-0" onClick={() => setIsOpen(!isOpen)}>
              <FaBars className="h-6 w-6 text-white" />
            </Button>

            {isOpen && (
              <div className="absolute top-16 right-0 w-full bg-[#1A1D2E] border-t border-white/10 py-4">
                <nav className="flex flex-col space-y-4 px-6">
                  <div className="cursor-pointer text-sm text-gray-400 hover:text-white transition-colors" onClick={() => {scrollToSection('inicio'); setIsOpen(false)}}>
                    Início
                  </div>
                  <div className="cursor-pointer text-sm text-gray-400 hover:text-white transition-colors" onClick={() => {scrollToSection('solucoes'); setIsOpen(false)}}>
                    Soluções
                  </div>
                  <div className="cursor-pointer text-sm text-gray-400 hover:text-white transition-colors" onClick={() => {scrollToSection('lideres'); setIsOpen(false)}}>
                    Equipe
                  </div>
                  <div className="cursor-pointer text-sm text-gray-400 hover:text-white transition-colors" onClick={() => {scrollToSection('contato'); setIsOpen(false)}}>
                    Contato
                  </div>
                  <Button className="bg-[#6C47FF] hover:bg-[#7C5AFF] w-full" onClick={() => {scrollToSection('contato'); setIsOpen(false)}}>
                    Entre em contato
                  </Button>
                </nav>
              </div>
            )}
          </div>
        </div>
      </header>
      <main className="pt-16">
        <section id="inicio" className="container text-center md:text-left pb-48 md:pb-0 md:min-h-screen py-32 md:py-0 flex flex-col lg:flex-row items-center gap-12">
          <div className="flex-1 space-y-6">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight">
              Inovação em Software e IA
            </h1>
            <p className="text-gray-400 text-lg max-w-[500px]">
              Transformamos ideias em soluções digitais avançadas. 
              Do conceito à realidade, impulsionamos seu negócio com tecnologia de ponta.
            </p>
            <Button className="bg-[#6C47FF] hover:bg-[#7C5AFF] text-lg px-6 py-3">
              Explore Soluções
              <ArrowRight className="ml-2 h-5 w-5" />
            </Button>
          </div>
          <div className="flex-1 h-[400px] w-full max-w-[600px] hidden md:block">
            <NetworkVisualization />
          </div>
        </section>

        <section id="solucoes" className="border-t border-white/5 bg-[#0D0F1B]/50">
          <div className="container py-24">
            <h2 className="text-4xl font-bold mb-12 text-white">Soluções Inovadoras</h2>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              {[
                {
                  title: "Desenvolvimento de Software Personalizado",
                  description: "Criamos soluções sob medida que impulsionam a eficiência operacional e oferecem vantagens competitivas únicas para o seu negócio.",
                  icon: Code
                },
                {
                  title: "Aplicativos Móveis de Alta Performance",
                  description: "Desenvolvemos apps intuitivos e robustos que proporcionam experiências excepcionais e engajam seus usuários em todas as plataformas.",
                  icon: Smartphone
                },
                {
                  title: "Integração Avançada de IA",
                  description: "Implementamos soluções de inteligência artificial de ponta que otimizam processos, fornecem insights valiosos e impulsionam a inovação em sua empresa.",
                  icon: Brain
                },
              ].map((solution, index) => (
                <Card key={index} className="bg-[#1A1D2E] border-white/10 p-8 hover:bg-[#22263A] transition-all hover:scale-105 shadow-lg">
                  <solution.icon className="h-12 w-12 text-[#8C67FF] mb-6" />
                  <h3 className="text-xl font-bold mb-4 text-white">{solution.title}</h3>
                  <p className="text-gray-300">{solution.description}</p>
                </Card>
              ))}
            </div>
          </div>
        </section>

        <section id="lideres" className="border-t border-white/5 bg-[#0D0F1B]/30">
          <div className="container py-24">
            <h2 className="text-4xl font-bold mb-12 text-white text-center">Nossa Equipe</h2>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-12">
              {[
                { 
                  name: "Guilherme Seguro", 
                  role: "Fundador e CTO (Diretor de Tecnologia)", 
                  description: "Líder da equipe técnica, arquiteto de soluções inovadoras, especialista em IA e desenvolvimento de software avançado.",
                  linkedin: "https://www.linkedin.com/in/guilherme-seguro/",
                  image: GuilhermeImage
                },
                {
                  name: "Pedro Cardoso", 
                  role: "Co-fundador e CSO (Diretor de Vendas)", 
                  description: "Líder da equipe de vendas, especialista em gestão de relacionamento com clientes e desenvolvimento de estratégias de crescimento.",
                  linkedin: "https://www.linkedin.com/in/p-cardoso/",
                  image: PedroImage
                },
                { 
                  name: "Tobias Gelasko", 
                  role: "CCO (Diretor Comercial)", 
                  description: "Especialista em gestão comercial, desenvolvimento de negócios e parcerias estratégicas.",
                  linkedin: "https://www.linkedin.com/in/tobias-gelasko-17578a295/",
                  image: TobiasImage
                },
                {
                  name: "Felipe Yohan Toller",
                  role: "Assistente Comercial",
                  description: "Assistente comercial, primeiro contato com o cliente e especialista em estratégias de crescimento e inovação.",
                  linkedin: "https://www.linkedin.com/in/felipe-yohan-toller-chacao-5005a717a/",
                  image: FelipeImage
                },
                { 
                  name: "Davi Nepomoceno", 
                  role: "Assistente Comercial", 
                  description: "Assistente comercial, primeiro contato com o cliente e especialista em estratégias de crescimento e inovação.",
                  linkedin: "",
                  image: DaviImage
                },
              ].map((member, index) => (
                <div key={index} className="flex flex-col items-center text-center">
                  <div className="relative w-48 h-48 mb-6 overflow-hidden rounded-full bg-[conic-gradient(from_225deg_at_center,_#6C47FF_0%,_#8C67FF_25%,_#6C47FF_50%,_#8C67FF_75%,_#6C47FF_100%)]">
                    <img
                      src={member.image}
                      alt={member.name}
                      width={192}
                      height={192}
                      className="object-cover brightness-105"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-[#0D0F1B] to-transparent opacity-50"></div>
                  </div>
                  <h3 className="text-xl font-bold text-white mb-1">{member.name}</h3>
                  <p className="text-gray-400 mb-2">{member.role}</p>
                  <p className="text-[12px] text-gray-300 mb-4 max-w-[330px]">{member.description}</p>
                  {member.linkedin ? (
                    <a
                      href={member.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-[#6C47FF] hover:text-[#8C67FF] transition-colors"
                    >
                      <Linkedin className="w-6 h-6" />
                    </a>
                  ) : (
                    <div className="relative">
                      <Linkedin className="w-6 h-6 text-gray-500" />
                      <div className="absolute top-1/2 left-1/2 w-8 h-[2px] bg-gray-500 -translate-x-1/2 -translate-y-1/2 rotate-45" />
                      <div className="absolute top-1/2 left-1/2 w-8 h-[2px] bg-gray-500 -translate-x-1/2 -translate-y-1/2 -rotate-45" />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </section>

        <section id="contato" className="border-t border-white/5 bg-[#151825]">
          <div className="container py-24">
            <div className="max-w-4xl mx-auto text-center space-y-10">
              <h2 className="md:text-4xl text-3xl font-bold text-white leading-tight">Pronto para transformar sua visão em realidade?</h2>
              <p className="md:text-lg text-base text-gray-300 max-w-2xl mx-auto">
                Entre em contato conosco e descubra como a Flynx pode impulsionar a inovação em seu negócio. 
                Estamos ansiosos para colaborar e criar soluções personalizadas.
              </p>
              <div className="flex flex-col md:flex-row justify-center space-y-6 md:space-y-0 md:space-x-12 text-gray-300">
                <div className="flex items-center text-left space-x-3">
                  <Mail className="w-6 h-6 text-[#6C47FF]" />
                  <div>
                    <h3 className="font-semibold mb-1 text-white">E-mail</h3>
                    <a href="mailto:contato@flynx.com.br" className="hover:text-white transition-colors">contato@flynx.com.br</a>
                  </div>
                </div>
                <div className="flex items-center text-left space-x-3">
                  <Phone className="w-6 h-6 text-[#6C47FF]" />
                  <div>
                    <h3 className="font-semibold mb-1 text-white">Telefone e WhatsApp</h3>
                    <a href="https://wa.me/5541984152304" className="hover:text-white transition-colors">+55 (41) 98415-2304</a>
                  </div>
                </div>
              </div>
              <form className="mt-12 space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <Input placeholder="Nome completo" className="bg-[#1A1D2E] border-white/10 text-white" value={nomeCompleto} onChange={(e) => setNomeCompleto(e.target.value)} />
                  <Input placeholder="Telefone" className="bg-[#1A1D2E] border-white/10 text-white" value={telefone} onChange={(e) => setTelefone(e.target.value)} />
                  <Input placeholder="E-mail empresarial" className="bg-[#1A1D2E] border-white/10 text-white" value={email} onChange={(e) => setEmail(e.target.value)} />
                  <Input placeholder="Empresa" className="bg-[#1A1D2E] border-white/10 text-white" value={empresa} onChange={(e) => setEmpresa(e.target.value)} />
                </div>
                <Textarea placeholder="Em que podemos ajudar?" className="bg-[#1A1D2E] border-white/10 text-white" rows={4} value={mensagem} onChange={(e) => setMensagem(e.target.value)} />
                
                  <Button disabled={!nomeCompleto || !telefone || !email || !empresa || !mensagem} className="bg-[#6C47FF] hover:bg-[#7C5AFF] text-white px-8 py-3" onClick={handleSubmit}>
                    Enviar mensagem
                  </Button>
              </form>
            </div>
          </div>
        </section>
      </main>

      <footer className="border-t border-white/5 bg-[#0D0F1B] px-6 py-10">
        <div className="container mx-auto flex flex-col md:flex-row justify-between items-center gap-4 px-8 lg:px-12">
          <p className="text-sm text-gray-400">
            © 2024 Flynx. Todos os direitos reservados.
          </p>
          <div className="flex items-center gap-8">
            <nav className="flex gap-8">
              <button onClick={() => setModalContent('terms')} className="text-sm text-gray-400 hover:text-white transition-colors">
                Termos
              </button>
              <button onClick={() => setModalContent('privacy')} className="text-sm text-gray-400 hover:text-white transition-colors">
                Privacidade
              </button>
            </nav>
            <div className="flex gap-4">
              <a href="https://wa.me/5541984152304" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white transition-colors">
                <FaWhatsapp className="w-5 h-5" />
              </a>
              <a href="https://www.linkedin.com/company/flynxdevelopment" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white transition-colors">
                <Linkedin className="w-5 h-5" />
              </a>
              <a href="https://www.instagram.com/flynxdev" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white transition-colors">
                <FaInstagram className="w-5 h-5" />
              </a>
            </div>
          </div>
        </div>
        {modalContent && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-[#1A1D2E] p-6 rounded-lg max-w-md w-full">
              <h3 className="text-xl font-bold mb-4">{modalContent === 'terms' ? 'Termos de Uso' : 'Política de Privacidade'}</h3>
              <p className="text-sm text-gray-300 mb-4">
                {modalContent === 'terms' 
                  ? 'A Flynx respeita sua privacidade e não coleta dados pessoais sem seu consentimento explícito. Utilizamos apenas informações necessárias para melhorar nossos serviços e sua experiência.'
                  : 'Não coletamos nenhum dado pessoal através deste site. Quaisquer informações fornecidas voluntariamente através do formulário de contato são utilizadas exclusivamente para responder à sua solicitação.'}
              </p>
              <button onClick={() => setModalContent(null)} className="bg-[#6C47FF] hover:bg-[#7C5AFF] text-white px-4 py-2 rounded">
                Fechar
              </button>
            </div>
          </div>
        )}
      </footer>

      {/* Chat Widget */}
      <div className="fixed bottom-6 right-6 z-50">
        <GoogleAnalytics />
        <a
          href="https://wa.me/5541984152304"
          target="_blank"
          rel="noopener noreferrer"
          className="block w-16 h-16 border-2 border-white/10 rounded-full bg-gradient-to-r from-[#6C47FF] to-[#8C67FF] shadow-lg overflow-hidden relative group"
          onClick={() => {
            // @ts-ignore
            gtag('event', 'conversion', {'send_to': 'AW-16457371015/O4WgCPun95AZEIebvqc9'});
          }}
        >
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="w-16 h-16 bg-[#1A1D2E] rounded-full flex items-center justify-center group-hover:scale-95 transition-transform duration-300">
              <FaWhatsapp className="w-8 h-8 text-[#6C47FF]" />
            </div>
            <div className="absolute inset-[25%] bg-[#6C47FF] rounded-full opacity-0 group-hover:opacity-20 transition-opacity duration-300"></div>
          </div>
        </a>
      </div>
    </div>
  )
}

